export const PLACEMENT = {
  TOP_START: "top-start",
  TOP_CENTER: "top-center",
  TOP_END: "top-end",
  BOTTOM_START: "bottom-start",
  BOTTOM_CENTER: "bottom-center",
  BOTTOM_END: "bottom-end",
  MIDDLE_START_TOP: "middle-start-top",
  MIDDLE_START_BOTTOM: "middle-start-bottom",
  MIDDLE_END_TOP: "middle-end-top",
  MIDDLE_END_BOTTOM: "middle-end-bottom",
};

export const DROPDOWN_ITEM_TYPE = {
  DEFAULT: "default",
  HEADER: "header",
  DIVIDER: "divider",
  CUSTOM: "custom",
};
