import React from "react";
import classNames from "classnames";

const Header = (props) => {
  const { headerStart, headerEnd, headerMiddle, className, container } = props;

  return (
    <>
      <header className={classNames("bg-white shadow-header", className)}>
        <div
          className={classNames(
            "header-wrapper",
            "h-20",
            container && "container mx-auto"
          )}
        >
          <div className="header-action header-action-start">{headerStart}</div>
          {headerMiddle && (
            <div className="header-action header-action-middle">
              {headerMiddle}
            </div>
          )}
          <div className="header-action header-action-end">{headerEnd}</div>
        </div>
      </header>
    </>
  );
};

export default Header;
