import React from "react";
import { useLocation } from "react-router";

const AppRoute = ({ component: Component, ...props }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <div className={isLoginPage ? "" : "container px-4 mx-auto"}>
      <Component {...props} />
    </div>
  );
};

export default AppRoute;
