import api from "./api";

export const login = (data) => {
  return api.post("/patron/patron-login", data);
};

export const updateDeviceToken = (data) => {
  return api.post("/patron/patron-update-device-token", data);
};

export const updateDeviceSubscription = (data) => {
  return api.post("/patron/patron-update-subscription-url", data);
};

export const removeDeviceToken = (data) => {
  return api.post("/patron/patron-remove-device-token", data);
};
