export const ROOT = "/";
export const ACCESS_DENIED_PATH = "/access-denied";
export const LOGIN_PATH = "/login";
export const DASHBOARD_PATH = "/dashboard";
export const PROFILES_PATH = "/profiles";
export const GROUPS_PATH = "/groups";
// export const FORMS_PATH = "/forms";
export const DISASTER_RELIEF_PATH = "/form-disaster-relief";
export const INCOME_GENERATION_PATH = "/form-income-generation";
export const LIFE_SAVING_PATH = "/form-life-saving";
export const INFRASTRUCTURAL_PATH = "/form-infrastructural";
export const PPROFILE_PATH = "/form-profile";
export const NUTRITION_PATH = "/form-nutrition-tracker";
export const HEALTH_TRACKER_PATH = "/form-health-tracker";
export const CHILD_EDU_PATH = "/form-child-education";
export const IMPACT_PATH = "/impact";
export const BLOG_PATH = "/blogs";
export const INDIVIDUAL_PATH = "/individuals";
