import React, { useState } from "react";
import UserDropdown from "./userDropdown";
import { RiMenu3Fill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import SdCloseMenu from "../svg/sdCloseMenu";
import {
  DASHBOARD_PATH,
  GROUPS_PATH,
  IMPACT_PATH,
  PROFILES_PATH,
} from "../../constants/route.constant";
import classNames from "classnames";
import Avatar from "../ui/avatar";
import useAuth from "../../hooks/useAuth";

const MenuBar = ({ user, className }) => {
  const location = useLocation();
  const active = location?.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const { signOut } = useAuth();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div className="hidden lg:flex items-center">
        <div className="mx-4 text-[14px] font-bold">{user?.user?.code}</div>
        <UserDropdown hoverable={true} />
      </div>
      <div className="lg:hidden flex cursor-pointer" onClick={toggleDrawer}>
        <RiMenu3Fill className="text-[28px]" />
      </div>

      <div className="lg:hidden">
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className="menuDrawer"
        >
          <div>
            <div className="flex justify-end relative">
              <button
                onClick={toggleDrawer}
                className="p-4 rounded-full absolute right-2 top-2"
              >
                <SdCloseMenu />
              </button>
            </div>
            <div className="mt-16 mx-5">
              <div className="flex items-center gap-4">
                <div
                  className={classNames(className, `flex items-center gap-2 `)}
                >
                  <Avatar
                    size={40}
                    shape="circle"
                    src={`/img/other/user.png`}
                  />
                </div>
                <div>
                  <div className="text-[14px] font-bold">
                    {user?.user?.code}
                  </div>
                  <div className="font-normal text-[14px] text-gray-900">
                    {user?.user?.generatedCode}
                  </div>
                </div>
              </div>

              <div className="flex items-center my-5">
                <ul className="list-none flex  items-start flex-col gap-4 text-[14px] font-bold">
                  <li
                    className={`cursor-pointer py-1 text-theme ${
                      active === DASHBOARD_PATH
                        ? "border-b-2 border-theme"
                        : "line-animation"
                    }`}
                  >
                    <Link to={DASHBOARD_PATH} onClick={toggleDrawer}>
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer py-1 text-theme ${
                      active.includes("profiles")
                        ? "border-b-2 border-theme"
                        : "line-animation"
                    }`}
                  >
                    <Link to={PROFILES_PATH} onClick={toggleDrawer}>
                      Individuals
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer py-1 text-theme ${
                      active.includes("groups")
                        ? "border-b-2 border-theme"
                        : "line-animation"
                    }`}
                  >
                    <Link to={GROUPS_PATH} onClick={toggleDrawer}>
                      Groups
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer py-1 text-theme ${
                      active.includes("impact")
                        ? "border-b-2 border-theme"
                        : "line-animation"
                    }`}
                  >
                    <Link to={IMPACT_PATH} onClick={toggleDrawer}>
                      Impact
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="bg-theme rounded-md py-1 px-3 w-fit"
                onClick={signOut}
              >
                <span className="text-white text-[13px]">Sign Out</span>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default MenuBar;
