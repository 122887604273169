import React from "react";
import {
  DASHBOARD_PATH,
  PROFILES_PATH,
  ACCESS_DENIED_PATH,
  GROUPS_PATH,
  // FORMS_PATH,
  INFRASTRUCTURAL_PATH,
  PPROFILE_PATH,
  NUTRITION_PATH,
  CHILD_EDU_PATH,
  HEALTH_TRACKER_PATH,
  LIFE_SAVING_PATH,
  INCOME_GENERATION_PATH,
  DISASTER_RELIEF_PATH,
  IMPACT_PATH,
  BLOG_PATH,
  INDIVIDUAL_PATH,
} from "../../constants/route.constant";

const appsRoute = [
  {
    key: "accessDenied",
    path: `${ACCESS_DENIED_PATH}`,
    component: React.lazy(() => import("../../views/access-denied")),
    authority: [],
  },
  {
    key: "dashboard",
    path: `${DASHBOARD_PATH}`,
    component: React.lazy(() => import("../../views/dashboard")),
    authority: [],
  },
  {
    key: "profiles",
    path: `${PROFILES_PATH}`,
    component: React.lazy(() => import("../../views/profiles")),
    authority: [],
  },
  {
    key: "profileDetails",
    path: `${PROFILES_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/profiles/viewProfileDetails")
    ),
    authority: [],
  },
  {
    key: "groups",
    path: `${GROUPS_PATH}`,
    component: React.lazy(() => import("../../views/groups")),
    authority: [],
  },
  {
    key: "groupDetails",
    path: `${GROUPS_PATH}/:profileId`,
    component: React.lazy(() => import("../../views/groups/groupDetails")),
    authority: [],
  },
  // {
  //   key: "forms",
  //   path: `${FORMS_PATH}`,
  //   component: React.lazy(() => import("../../views/forms")),
  //   authority: [],
  // },
  {
    key: "disasterRelief",
    path: `${DISASTER_RELIEF_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/diasterRelief/index.js")
    ),
    authority: [],
  },
  {
    key: "incomeGeneration",
    path: `${INCOME_GENERATION_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/incomeGeneration/index.js")
    ),
    authority: [],
  },
  {
    key: "lifeSaving",
    path: `${LIFE_SAVING_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/lifeSaving/index.js")
    ),
    authority: [],
  },
  {
    key: "infrastructural",
    path: `${INFRASTRUCTURAL_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/infrastructural/index.js")
    ),
    authority: [],
  },
  {
    key: "profile",
    path: `${PPROFILE_PATH}/:profileId`,
    component: React.lazy(() => import("../../views/forms/pProfile/index.js")),
    authority: [],
  },
  {
    key: "nutritiontracker",
    path: `${NUTRITION_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/nutritionTracker/index.js")
    ),
    authority: [],
  },
  {
    key: "healthTracker",
    path: `${HEALTH_TRACKER_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/healthTracker/index.js")
    ),
    authority: [],
  },
  {
    key: "childEducation",
    path: `${CHILD_EDU_PATH}/:profileId`,
    component: React.lazy(() =>
      import("../../views/forms/childEducation/index.js")
    ),
    authority: [],
  },
  {
    key: "blogs",
    path: `${BLOG_PATH}/:blogId`,
    component: React.lazy(() => import("../../views/blogs")),
    authority: [],
  },
  {
    key: "impact",
    path: `${IMPACT_PATH}`,
    component: React.lazy(() => import("../../views/impact")),
    authority: [],
  },
  {
    key: "individuals",
    path: `${INDIVIDUAL_PATH}`,
    component: React.lazy(() => import("../../views/individuals")),
    authority: [],
  },
];

export default appsRoute;
