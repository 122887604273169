import React from 'react'

const SdNavLogo = (props) => {
  const { color = "#001070" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="35"
      viewBox="0 0 1584 575"
      fill="none"
    >
      <path
        d="M698 339.05H732.68C732.68 362.33 751.84 375.19 776.26 375.19C798.42 375.19 817.37 363.56 817.37 344.4C817.37 323.68 795.07 318.46 769.96 312.55C738.08 304.81 701.53 296 701.53 253C701.53 215.41 730 193.23 774.39 193.23C819.57 193.23 846.31 217.84 846.31 257.12H812.52C812.52 236.35 795.36 225.12 773.62 225.12C752.86 225.12 735.7 234.62 735.7 251.57C735.7 270.8 757.11 276.03 781.83 281.93C814.22 289.93 852.35 299.24 852.35 344C852.35 385.66 818.72 407.81 776.55 407.81C729.84 407.83 698 381.6 698 339.05Z"
        fill={color}
      />
      <path
        d="M1070.88 300.42C1070.88 360.81 1026.46 404.63 965.26 404.63H891.83V196.37H965.26C1026.55 196.37 1070.88 240.11 1070.88 300.42ZM1035.94 300.42C1035.94 258.42 1006.38 228.25 964.28 228.25H926V372.76H964.27C1006.38 372.76 1035.94 342.35 1035.94 300.42Z"
        fill={color}
      />
      <path
        d="M1197.75 374.64V404.64H1173.2C1138.01 404.64 1116.29 382.93 1116.29 347.43V272.27H1087.56V265.88L1143.43 206.47H1149.06V244H1196.86V272.3H1149.31V344.85C1149.31 364.01 1159.97 374.67 1179.43 374.67L1197.75 374.64Z"
        fill={color}
      />
      <path
        d="M1380.65 324.68C1380.61 328.306 1380.39 331.928 1379.97 335.53H1249.57C1253.69 361.53 1272.44 377.53 1298.74 377.53C1317.94 377.53 1333.48 368.66 1341.97 354.01H1376.74C1363.95 387.93 1334.62 408.51 1298.74 408.51C1251.74 408.51 1216.2 372.25 1216.2 324.26C1216.2 276.27 1251.69 240.02 1298.74 240.02C1347.92 240.02 1380.65 277.89 1380.65 324.64V324.68ZM1298.74 269.88C1273.9 269.88 1255.8 284.88 1250.39 308.7H1347.54C1341.61 284.3 1323.22 269.89 1298.74 269.89V269.88Z"
        fill={color}
      />
      <path
        d="M1488.13 408.55C1440.27 408.55 1403.93 372.12 1403.93 324.14C1403.93 276.16 1440.19 240.06 1488.22 240.06C1527.92 240.06 1558.95 264.38 1567.87 301.24H1535.15C1526.7 282.76 1509.07 271.59 1488.15 271.59C1459.23 271.59 1437.57 294.44 1437.57 324.22C1437.57 354 1459.57 377.01 1488.15 377.01C1509.15 377.01 1526.21 365.67 1534.96 346.13H1568.28C1559.44 383.67 1527.82 408.55 1488.15 408.55H1488.13Z"
        fill={color}
      />
      <path
        d="M707.57 446.89C709.253 445.867 711.191 445.34 713.16 445.37C715.518 445.295 717.841 445.952 719.81 447.25C721.6 448.479 722.974 450.222 723.75 452.25H728.09C727.252 449.127 725.407 446.367 722.84 444.4C720.033 442.403 716.643 441.394 713.2 441.53C710.421 441.479 707.682 442.191 705.28 443.59C702.986 444.971 701.128 446.971 699.92 449.36C698.605 451.983 697.946 454.886 698 457.82C697.947 460.731 698.606 463.612 699.92 466.21C701.132 468.592 702.989 470.584 705.28 471.96C707.68 473.363 710.421 474.076 713.2 474.02C716.643 474.156 720.033 473.147 722.84 471.15C725.409 469.185 727.255 466.424 728.09 463.3H723.75C722.977 465.329 721.602 467.073 719.81 468.3C717.841 469.598 715.518 470.254 713.16 470.18C711.186 470.22 709.242 469.69 707.561 468.655C705.88 467.619 704.533 466.121 703.68 464.34C702.689 462.298 702.199 460.049 702.25 457.78C702.199 455.488 702.689 453.217 703.68 451.15C704.555 449.396 705.903 447.921 707.57 446.89Z"
        fill={color}
      />
      <path
        d="M757.32 457.66C758.821 455.926 759.598 453.681 759.49 451.39C759.548 450.114 759.336 448.84 758.869 447.652C758.402 446.463 757.691 445.386 756.78 444.49C754.973 442.757 752.257 441.89 748.63 441.89H738.13V473.79H742.25V460.79H746.73L755.06 473.79H759.72L751 460.62C753.39 460.412 755.63 459.363 757.32 457.66ZM742.25 457.77V445.46H748.25C752.917 445.46 755.25 447.513 755.25 451.62C755.25 455.727 752.917 457.777 748.25 457.77H742.25Z"
        fill={color}
      />
      <path
        d="M803.52 473.79H807.64V459.49H819.45V456.28H807.64V445.24H821.57V441.89H803.52V473.79Z"
        fill={color}
      />
      <path
        d="M828.4 445.24H837.41V473.79H841.53V445.24H850.53V441.89H828.4V445.24Z"
        fill={color}
      />
      <path d="M863.25 441.89H859.13V473.79H863.25V441.89Z" fill={color} />
      <path
        d="M895.06 467.14L878.49 441.89H874.38V473.79H878.49V448.59L895.06 473.79H899.17V441.89H895.06V467.14Z"
        fill={color}
      />
      <path
        d="M923.92 459.81H936.46C936.299 461.827 935.654 463.775 934.58 465.49C933.597 467.05 932.216 468.32 930.58 469.17C928.918 470.028 927.07 470.464 925.2 470.44C923.075 470.481 920.976 469.956 919.12 468.92C917.343 467.909 915.901 466.401 914.97 464.58C913.931 462.496 913.42 460.188 913.48 457.86C913.424 455.573 913.928 453.307 914.95 451.26C915.847 449.468 917.234 447.966 918.95 446.93C920.724 445.908 922.743 445.386 924.79 445.42C927.01 445.36 929.196 445.974 931.06 447.18C932.775 448.299 934.093 449.93 934.83 451.84H939.18C938.406 448.822 936.607 446.167 934.09 444.33C931.353 442.424 928.073 441.456 924.74 441.57C921.902 441.516 919.102 442.235 916.64 443.65C914.306 445.022 912.404 447.021 911.15 449.42C909.854 452.045 909.18 454.933 909.18 457.86C909.18 460.787 909.854 463.675 911.15 466.3C912.402 468.696 914.305 470.689 916.64 472.05C919.124 473.451 921.939 474.159 924.79 474.1C927.407 474.147 929.992 473.524 932.3 472.29C934.442 471.143 936.25 469.462 937.55 467.41C938.858 465.331 939.662 462.975 939.9 460.53V456.73H923.9L923.92 459.81Z"
        fill={color}
      />
      <path
        d="M977.54 469.22L967.82 441.89H963.29L974.96 473.79H980.17L991.89 441.89H987.41L977.54 469.22Z"
        fill={color}
      />
      <path
        d="M1009.94 442.47L998.13 473.79H1002.57L1005.24 466.41H1019.24L1021.91 473.79H1026.35L1014.58 442.47H1009.94ZM1006.41 463.2L1012.25 447.09L1018.09 463.2H1006.41Z"
        fill={color}
      />
      <path
        d="M1039.17 441.89H1035.05V473.79H1050.48V470.57H1039.17V441.89Z"
        fill={color}
      />
      <path
        d="M1078.63 461C1078.63 464.1 1077.96 466.407 1076.63 467.92C1075.91 468.687 1075.03 469.286 1074.05 469.677C1073.07 470.067 1072.02 470.239 1070.97 470.18C1069.91 470.239 1068.85 470.07 1067.86 469.683C1066.87 469.297 1065.97 468.702 1065.23 467.94C1063.84 466.45 1063.15 464.13 1063.15 461V441.89H1059V461C1059 465.34 1060.08 468.607 1062.24 470.8C1064.62 472.917 1067.69 474.087 1070.88 474.087C1074.07 474.087 1077.14 472.917 1079.52 470.8C1081.68 468.607 1082.76 465.34 1082.76 461V441.89H1078.64L1078.63 461Z"
        fill={color}
      />
      <path
        d="M1093.65 473.79H1111.25V470.44H1097.77V459.31H1109.9V456.14H1097.77V445.24H1111.25V441.89H1093.65V473.79Z"
        fill={color}
      />
      <path
        d="M1119.26 470.76V475H1121.57V475.41C1121.62 476.09 1121.44 476.768 1121.05 477.33C1120.84 477.549 1120.58 477.718 1120.29 477.825C1120.01 477.933 1119.7 477.975 1119.4 477.95V479.71C1120.06 479.759 1120.72 479.655 1121.33 479.407C1121.94 479.159 1122.49 478.773 1122.93 478.28C1123.75 477.2 1124.17 475.865 1124.1 474.51C1124.08 473.248 1123.87 471.997 1123.47 470.8L1119.26 470.76Z"
        fill={color}
      />
      <path
        d="M1164.89 457.91C1163.27 457.162 1161.6 456.527 1159.89 456.01C1158.53 455.59 1157.2 455.096 1155.89 454.53C1154.95 454.124 1154.12 453.504 1153.46 452.72C1152.78 451.873 1152.42 450.808 1152.46 449.72C1152.42 449.039 1152.53 448.358 1152.79 447.726C1153.05 447.095 1153.45 446.529 1153.95 446.07C1155.08 445.164 1156.5 444.705 1157.95 444.78C1159.52 444.694 1161.06 445.213 1162.25 446.23C1163.25 447.102 1163.93 448.28 1164.19 449.58H1168.63C1168.36 447.294 1167.2 445.205 1165.41 443.76C1163.27 442.143 1160.63 441.336 1157.95 441.48C1156.14 441.426 1154.34 441.768 1152.68 442.48C1151.27 443.097 1150.07 444.121 1149.24 445.42C1148.43 446.737 1148.01 448.262 1148.04 449.81C1147.96 451.461 1148.41 453.095 1149.33 454.47C1150.13 455.6 1151.21 456.511 1152.45 457.12C1154.03 457.847 1155.65 458.475 1157.31 459C1158.7 459.415 1160.06 459.919 1161.39 460.51C1162.36 460.96 1163.22 461.619 1163.9 462.44C1164.6 463.341 1164.95 464.461 1164.9 465.6C1164.93 466.285 1164.81 466.967 1164.56 467.606C1164.31 468.244 1163.93 468.824 1163.45 469.31C1162.89 469.82 1162.23 470.211 1161.51 470.46C1160.8 470.709 1160.04 470.811 1159.28 470.76C1158.05 470.794 1156.82 470.508 1155.73 469.93C1154.85 469.446 1154.11 468.76 1153.55 467.928C1153 467.097 1152.65 466.144 1152.54 465.15H1148.15C1148.28 466.808 1148.87 468.398 1149.85 469.74C1150.87 471.117 1152.23 472.208 1153.79 472.91C1155.56 473.705 1157.48 474.098 1159.42 474.06C1161.32 474.125 1163.21 473.706 1164.9 472.84C1166.28 472.12 1167.43 471.02 1168.2 469.67C1168.92 468.387 1169.29 466.94 1169.28 465.47C1169.37 463.749 1168.91 462.045 1167.97 460.6C1167.18 459.461 1166.12 458.536 1164.89 457.91Z"
        fill={color}
      />
      <path
        d="M1199.76 455.69H1183.56V441.89H1179.44V473.79H1183.56V459.04H1199.76V473.79H1203.92V441.89H1199.76V455.69Z"
        fill={color}
      />
      <path
        d="M1224.42 442.47L1212.61 473.79H1217L1219.67 466.41H1233.67L1236.34 473.79H1240.77L1229.01 442.47H1224.42ZM1220.89 463.2L1226.73 447.09L1232.56 463.2H1220.89Z"
        fill={color}
      />
      <path
        d="M1259.67 441.89H1249.53V473.79H1253.65V460.94H1259.65C1263.17 460.94 1265.87 460.103 1267.75 458.43C1268.7 457.539 1269.44 456.45 1269.93 455.24C1270.41 454.031 1270.62 452.731 1270.55 451.43C1270.62 450.137 1270.41 448.844 1269.94 447.637C1269.48 446.43 1268.75 445.337 1267.83 444.43C1266.05 442.74 1263.31 441.89 1259.67 441.89ZM1264.67 455.89C1263.57 456.95 1261.77 457.48 1259.26 457.48H1253.61V445.33H1259.26C1261.77 445.33 1263.58 445.85 1264.67 446.89C1265.24 447.491 1265.69 448.205 1265.97 448.987C1266.26 449.768 1266.37 450.6 1266.32 451.43C1266.38 452.248 1266.26 453.068 1265.99 453.84C1265.71 454.611 1265.28 455.316 1264.71 455.91L1264.67 455.89Z"
        fill={color}
      />
      <path d="M1284.01 441.89H1279.89V473.79H1284.01V441.89Z" fill={color} />
      <path
        d="M1315.82 467.14L1299.26 441.89H1295.14V473.79H1299.26V448.59L1315.82 473.79H1319.94V441.89H1315.82V467.14Z"
        fill={color}
      />
      <path
        d="M1344.69 459.81H1357.22C1357.06 461.827 1356.41 463.775 1355.34 465.49C1354.36 467.051 1352.98 468.321 1351.34 469.17C1349.67 470.027 1347.82 470.463 1345.95 470.44C1343.82 470.481 1341.73 469.956 1339.87 468.92C1338.09 467.912 1336.65 466.402 1335.73 464.58C1334.75 462.496 1334.25 460.222 1334.25 457.92C1334.25 455.618 1334.75 453.344 1335.73 451.26C1336.63 449.472 1338.02 447.972 1339.73 446.93C1341.5 445.908 1343.52 445.386 1345.57 445.42C1347.79 445.36 1349.98 445.974 1351.84 447.18C1353.55 448.302 1354.88 449.931 1355.62 451.84H1360C1359.23 448.822 1357.43 446.167 1354.91 444.33C1352.18 442.424 1348.9 441.456 1345.57 441.57C1342.73 441.513 1339.93 442.233 1337.47 443.65C1335.13 445.018 1333.22 447.018 1331.97 449.42C1330.67 452.045 1330 454.933 1330 457.86C1330 460.787 1330.67 463.675 1331.97 466.3C1333.22 468.699 1335.13 470.692 1337.47 472.05C1339.95 473.453 1342.76 474.161 1345.61 474.1C1348.23 474.149 1350.81 473.525 1353.12 472.29C1355.26 471.143 1357.07 469.462 1358.37 467.41C1359.68 465.331 1360.48 462.975 1360.72 460.53V456.73H1344.72L1344.69 459.81Z"
        fill={color}
      />
      <path d="M1390.62 441.89H1386.5V473.79H1390.62V441.89Z" fill={color} />
      <path
        d="M1417.81 468.76L1406.68 442.43H1401.74V473.79H1405.86V448.86L1415.91 473.79H1419.57L1429.57 448.76V473.79H1433.69V442.43H1428.76L1417.81 468.76Z"
        fill={color}
      />
      <path
        d="M1455 441.89H1444.86V473.79H1448.98V460.94H1454.98C1458.51 460.94 1461.2 460.1 1463.08 458.43C1464.03 457.539 1464.77 456.45 1465.26 455.24C1465.74 454.031 1465.95 452.731 1465.88 451.43C1465.95 450.138 1465.74 448.846 1465.28 447.639C1464.81 446.433 1464.09 445.339 1463.17 444.43C1461.34 442.74 1458.6 441.89 1455 441.89ZM1460 455.89C1458.9 456.95 1457.1 457.48 1454.59 457.48H1448.94V445.33H1454.59C1457.1 445.33 1458.91 445.85 1460 446.89C1460.57 447.491 1461.02 448.205 1461.3 448.987C1461.59 449.768 1461.7 450.6 1461.65 451.43C1461.7 452.251 1461.58 453.073 1461.3 453.844C1461.01 454.616 1460.57 455.32 1460 455.91V455.89Z"
        fill={color}
      />
      <path
        d="M1484.55 442.47L1472.74 473.79H1477.17L1479.84 466.41H1493.84L1496.51 473.79H1501L1489.24 442.47H1484.55ZM1481 463.2L1486.84 447.09L1492.67 463.2H1481Z"
        fill={color}
      />
      <path
        d="M779.81 442L768 473.32H772.43L775.1 465.94H789.1L791.77 473.32H796.26L784.5 442H779.81ZM776.26 462.73L782.1 446.62L787.93 462.73H776.26Z"
        fill={color}
      />
      <path
        d="M1518.15 446.89C1519.83 445.867 1521.76 445.341 1523.73 445.37C1526.09 445.295 1528.42 445.952 1530.39 447.25C1532.18 448.477 1533.55 450.221 1534.32 452.25H1538.67C1537.83 449.129 1535.98 446.371 1533.42 444.4C1530.61 442.405 1527.22 441.395 1523.78 441.53C1521 441.476 1518.26 442.189 1515.86 443.59C1513.56 444.968 1511.71 446.969 1510.5 449.36C1509.18 451.983 1508.53 454.886 1508.58 457.82C1508.53 460.731 1509.19 463.612 1510.5 466.21C1511.71 468.594 1513.57 470.587 1515.86 471.96C1518.26 473.367 1521 474.081 1523.78 474.02C1527.22 474.155 1530.61 473.145 1533.42 471.15C1535.99 469.181 1537.83 466.423 1538.67 463.3H1534.32C1533.55 465.33 1532.18 467.076 1530.39 468.3C1528.42 469.598 1526.09 470.255 1523.73 470.18C1521.76 470.218 1519.82 469.687 1518.14 468.652C1516.46 467.616 1515.11 466.119 1514.26 464.34C1513.26 462.3 1512.77 460.05 1512.83 457.78C1512.77 455.488 1513.26 453.215 1514.26 451.15C1515.13 449.392 1516.48 447.915 1518.15 446.89Z"
        fill={color}
      />
      <path
        d="M1546.13 441.89V445.24H1555.13V473.79H1559.25V445.24H1568.26V441.89H1546.13Z"
        fill={color}
      />
      <path
        d="M531.21 18.3201V78.9201H305.21C287.618 78.9161 270.198 82.3777 253.944 89.107C237.691 95.8364 222.922 105.702 210.481 118.14C198.04 130.577 188.172 145.344 181.439 161.597C174.706 177.849 171.24 195.268 171.24 212.86C171.24 265.66 202.91 313.24 249.61 334.72C258.011 338.59 266.796 341.563 275.82 343.59L276.46 343.7C278.46 344.12 280.21 344.46 281.92 344.76L283 345L290.09 346.13L290.88 346.25H358.88C364.415 346.25 369.724 348.449 373.638 352.363C377.551 356.277 379.75 361.585 379.75 367.12C379.75 372.655 377.551 377.963 373.638 381.877C369.724 385.791 364.415 387.99 358.88 387.99H132.88V327.4L72.2902 388L15.7402 444.55L132.86 561.68V501.14L188.54 502.14L189.54 501.14H358.89C376.486 501.144 393.909 497.68 410.165 490.947C426.421 484.213 441.191 474.342 453.63 461.898C466.069 449.453 475.934 434.679 482.66 418.42C489.386 402.161 492.842 384.735 492.83 367.14C492.83 314.34 461.17 266.76 414.46 245.28C406.059 241.412 397.274 238.439 388.25 236.41L387.6 236.3C385.6 235.88 383.85 235.54 382.15 235.24L381.01 235.01L374 233.85L373.2 233.73H305.2C299.665 233.73 294.357 231.531 290.443 227.617C286.529 223.703 284.33 218.395 284.33 212.86C284.33 207.325 286.529 202.017 290.443 198.103C294.357 194.189 299.665 191.99 305.2 191.99H531.2V252.6L591.78 192L648.33 135.45L531.21 18.3201Z"
        fill={color}
      />
    </svg>
  );
};

export default SdNavLogo
