import { combineReducers } from "redux";
import auth from "./auth";
import base from "./base";
import filter from "./slices/filterSlice";
import pagination from "./slices/paginationSlice";
import groupPagination from "./slices/groupSlice";
import gtpPagination from "./slices/groupToProfileSlice";
import map from "./slices/mapSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    base,
    filter,
    pagination,
    groupPagination,
    gtpPagination,
    map,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
