import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { APP_NAME } from "../../constants/app.constant";
import { Link } from "react-router-dom";
import { DASHBOARD_PATH } from "../../constants/route.constant";
import SdNavLogo from "../svg/sdNavLogo";

const LOGO_SRC_PATH = "/img/logo/";

const NavLogo = (props) => {
  const {
    isAuth = false,
    type,
    mode,
    gutter,
    className,
    imgClass,
    style,
    logoWidth,
  } = props;

  return (
    <div
      className={classNames("logo", className, gutter)}
      style={{
        ...style,
        ...{ width: logoWidth },
      }}
    >
      <Link to={DASHBOARD_PATH}>
        {isAuth ? (
          <div className={imgClass}>
            <SdNavLogo />
          </div>
        ) : (
          <img
            className={imgClass}
            src={`${LOGO_SRC_PATH}logo-${mode}-${type}.png`}
            alt={`${APP_NAME} logo`}
          />
        )}
        {/* <img
          className={imgClass}
          src={
            isAuth
              ? `${LOGO_SRC_PATH}logo-nav.png`
              : `${LOGO_SRC_PATH}logo-${mode}-${type}.png`
          }
          alt={`${APP_NAME} logo`}
        /> */}
      </Link>
    </div>
  );
};

NavLogo.propTypes = {
  mode: PropTypes.oneOf(["light", "dark"]),
  type: PropTypes.oneOf(["full", "streamline"]),
  gutter: PropTypes.string,
  imgClass: PropTypes.string,
  logoWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NavLogo;
